import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MatPaginator, MAT_DIALOG_DATA, PageEvent, Sort, MatDialogConfig, MatDialog } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import jsPDF from 'jspdf';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { AuthService } from '../../Services/auth.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { DateFormatService } from '../../Services/date-format.service';
@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})
export class QrCodeComponent implements OnInit {
  qr: string = "";
  qr660: string = "";
  binlist: any = [];
  sortedbinlist: any = []
  data: any;
  url = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  title: string;
  role;
  depo;
  constructor(private dialogRef: MatDialogRef<QrCodeComponent>,
    private  dialog : MatDialog,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService,
    private firestore: AngularFirestore,
    private authService: AuthService,
    private angularFireAuth: AngularFireAuth,
    @Inject(MAT_DIALOG_DATA) data,
  ) {
    this.title = data.name
    this.data = data;
    this.binlist = data.bin
    this.sortedbinlist = this.binlist.slice();
    this.shopname = data.shop_name

    this.authService.currentRole.subscribe(role => this.role = role);
    this.authService.currentDepo.subscribe(depo => this.depo = depo);
    this.initDepo();
  }

  binsizes = [
    '240',
    '660',
  ]
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  sortedu = {
    active: '',
    direction: ''
  };

  bin = {
    address: '',
    bin_size: '',
    id: '',
    last_scan: '',
    license_num: '',
    location: '',
    depo:''
  }
  shopname: any;
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.sortedbinlist = this.binlist.slice();
    this.sortbin();
    this.limitbin();
  }

  limitbin() {
    this.sortedbinlist = this.sortedbinlist.slice(this.offset, (this.offset + this.pageSize));
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sortedbinlist = this.binlist.slice();

    if (!sort.active || sort.direction === '') {
      this.sortedbinlist = this.binlist.slice();
      this.limitbin();
      return;
    }

    this.sortedbinlist = this.sortedbinlist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'location': return this.compare(a.location, b.location, isAsc);
        case 'bin': return this.compare(a.bin_size, b.bin_size, isAsc);
        case 'license_num': return this.compare(a.license_num, b.license_num, isAsc);
        case 'depo': return this.compare(a.depo || '', b.depo || '', isAsc);

        default: return 0;
      }
    });
    this.limitbin();
  }


  sortbin() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sortedbinlist = this.sortedbinlist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'location': return this.compare(a.location, b.location, isAsc);
        case 'bin': return this.compare(a.bin_size, b.bin_size, isAsc);
        case 'license_num': return this.compare(a.license_num, b.license_num, isAsc);
        case 'depo': return this.compare(a.depo || '', b.depo || '', isAsc);
        default: return 0;
      }
    });
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }



  ngOnInit() {
  }

  cancel() {
    this.dialogRef.close(this.binlist);
  }

  getBase64Image(img) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0, 0);
    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }

  download(bin) {
    var img = new Image()
    img.src = 'assets/logo1.jpg'
    var img1 = new Image()
    img1.src = 'assets/logo2.jpg'
    const doc = new jsPDF('portrait', 'mm', 'a4');
    const qrcode = document.getElementById('a4' + bin.id);
    const imageData = this.getBase64Image(qrcode.firstChild.firstChild);

    doc.addImage(imageData, 'JPG', 13, 100);
    doc.addImage(img, 'png', 90, 5, 30, 30)
    doc.addImage(img1, 'png', 70, 35, 70, 20)
    doc.line(0, 58, 280, 58)
    doc.setFontType("bold");
    doc.setFontSize(20);

    const splitName = doc.splitTextToSize(bin.location.toUpperCase(), 170);
    doc.text(splitName, doc.internal.pageSize.getWidth() / 2, 70, { align: "center" });
    doc.setFontType("normal");

    doc.setFontSize(18);
  
    const splitName2 = doc.splitTextToSize(bin.address.toUpperCase(), 170);
    doc.text(splitName2, doc.internal.pageSize.getWidth() / 2, 90, { align: "center" });

    doc.line(0, 100, 280, 100)
    doc.setFontSize(36);
    const splitTitle2 = doc.splitTextToSize(bin.bin_size + 'L', 150);
    doc.text(splitTitle2, doc.internal.pageSize.getWidth() / 2, 285, { align: "center" });

    doc.save(this.title.replace(/\s/g, '-') + bin.id + "@QRCode" + '.pdf');
  }

  newQr(){
    this.addQr = true;
    this.bin = {
      address: '',
      bin_size: '',
      id: '',
      last_scan: '',
      license_num: '',
      location: '',
      depo:''
    }
    this.editQrf = false;
    this.editIndex = null;
  }

  addQr = false;
  editQrf = false;
  editIndex = null;

  back(){
    this.addQr = false;
    this.editQrf = false;
    this.editIndex = null;
  }

  confirm(){
    this.bin.id = String(this.binlist.length);
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Adakah anda menambahkan Kod QR ini?'
    };

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        this.spinner.show();
        if(this.editQrf){
          this.bin.depo = this.bin.depo || ''
          this.binlist[this.editIndex] = this.bin;

          this.firestore.collection("users").doc(this.data.key).update({bin:this.binlist}).then(r=>{
            this.spinner.hide();
            this.toaster.success('Kemaskini berjaya');
            this.addLog(this.bin, 'user', `QR code ${this.bin.location} telah dikemaskini. \nNama Pemilik Premis: ${this.data.name}\n Name Premis: ${this.data.shop_name}\n `)
            this.addQr = false;
            this.editQrf = false;
            this.bin = {
              address: '',
              bin_size: '',
              id: '',
              last_scan: '',
              license_num: '',
              location: '',
              depo:''
            }
            this.sortedbinlist = this.binlist.slice();
  
          })
        }else{
          this.binlist.push(this.bin);
          this.firestore.collection("users").doc(this.data.key).update({bin:this.binlist}).then(r=>{
            this.spinner.hide();
            this.toaster.success('Tambah berjaya');
            this.addLog(this.bin, 'user', `QR code ${this.bin.location} telah ditambahkan. \nNama Pemilik Premis: ${this.data.name}\n Name Premis: ${this.data.shop_name}\n `)
            this.addQr = false;
            this.editQrf = false;
            this.bin = {
              address: '',
              bin_size: '',
              id: '',
              last_scan: '',
              license_num: '',
              location: '',
              depo:''
            }
            this.sortedbinlist = this.binlist.slice();
  
          })
        }
      
      }
    })
    
  }

  deleteQr(bin,index){
    this.bin.id = String(this.binlist.length);

    let temp = this.binlist.slice();

    temp.splice(index,1);

    for (let index = 0; index < temp.length; index++) {
      temp[index].id = index+1;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Adakah anda ingin padamkan Kod QR ini?'
    };

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        this.spinner.show();
        this.binlist = temp;
        this.firestore.collection("users").doc(this.data.key).update({bin:temp}).then(r=>{
          this.spinner.hide();
          this.toaster.success('Padam berjaya');
          this.addLog(bin, 'user', `QR code ${bin.location} telah dipadam. \nNama Pemilik Premis: ${this.data.name}\n Name Premis: ${this.data.shop_name}\n `)
          this.sortedbinlist = this.binlist.slice();

        })
      }
    })
  }

  editQr(bin, index){
    this.addQr = true;
    this.editQrf = true;
    this.bin = {
      address: bin.address,
      bin_size: bin.bin_size,
      id: bin.id,
      last_scan: bin.last_scan,
      license_num: bin.license_num,
      location: bin.location,
      depo:bin.depo
    }
    this.editIndex = index;

  }

  depoList = [];
  async initDepo() {
    this.spinner.show();
    this.depoList = [];
    await this.firestore.collection('depohs').get().forEach(de => {
      de.forEach(async d => {
        if (d.data().Show) {
          const info = {
            key: d.id,
            ...d.data(),
          }
          this.depoList.push(info)
        }
      })
      this.depoList = this.depoList.sort((a, b) => this.compare(a.Seq, b.Seq, true))

      if (this.role === 'User') {
        this.depoList = this.depoList.filter(item => this.depo.includes(item.key));
      }
      this.spinner.hide();
    })
  }

  private dateFormat = new DateFormatService();

  async addLog(detail,type, message){

    this.angularFireAuth.authState.subscribe(async authState => {
      if(authState){
        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          message: message,
          date: new Date(),
          data: detail,
          updatedBy: authState.email,
          dateFormat: dateFormat,
          type:type
        };
        await this.firestore.collection('AdminLog').add(info);
      
      }
     });

   
  }
}
